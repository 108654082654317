import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternating from '../../components/columnAlternatingStyled'
import { MDBContainer } from 'mdbreact'
import moment from 'moment'

const EventsPage = ({ data }) => {
  const events = data.allContentfulEvent.edges
   const heroImage = data.heroImage
   const conference = data.conference
  return (
    <>
      <div id="sub-page">
        <Layout>
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="UNICOM Government events"
            subtitle="UNICOM Government regularly attends, sponsors and presents at various industry and corporate events."
            type="events"
          /> 

          <section className="bg-white">
            <MDBContainer>
                 {events.map(({ node }, index) => (
                      <ColumnAlternating
                        key={index}
                        title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span><br /><br /> ${node.title}`}
                        subtitle={node.subtitle}
                        image={node.image ? node.image.localFile.childImageSharp.gatsbyImageData : conference.childImageSharp.gatsbyImageData}
                        imageAltText="unicom government event"
                        titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                        subtitleClass="font-w-400 letter-spacing-1 text-medium"
                        link={`/events/${node.slug}/`}
                        textCol={'8'}
                        imageCol={'4'}
                        colour="ficon-dark-blue"
                      />
                    )
                 )}
            </MDBContainer>
          </section>
        </Layout>
      </div>
    </>
  )
}
export default EventsPage

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="UNICOM Government events"
      description="UNICOM Government regularly attends, sponsors and presents at relevant industry and corporate events."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query { 
    allContentfulEvent(
      filter: {
        publishTo: {
          elemMatch: { url: { eq: "https://www.unicomgov.com" } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          subtitle
          date
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 250, quality: 90)
              }
            }
          }
        }
      }
    }
    conference: file(name: { eq: "conference" }) {
      childImageSharp {
        gatsbyImageData(width: 960, quality: 90)
      }
    }
    heroImage: file(name: { eq: "events-default" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
  }
`